import React from 'react';


const ContactPage = () => {
  return (
    (<>
      <div class="context">
        <h1>BoatVal</h1>
        <h3>building online presence solutions</h3>
        <h3>for the marine industry.  </h3>
        <h3>   contact us at info at boatval dot com</h3>
      </div>


      <div class="area" >
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div >
    </>)
  );
};

export default ContactPage;
